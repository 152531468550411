<template>
  <v-dialog v-model="dialog" max-width="500px" max-height="500px">
      <v-card>
        <v-img contain :src="url">
          <v-btn icon large color="grey darken-1" @click="dialog = false">
            <v-icon large>mdi-close</v-icon>
          </v-btn>
        </v-img>
      </v-card>
    </v-dialog>
</template>

<script>
export default {
  props:['url'],
  data() {
    return {
      dialog: false,
    }
  },
  methods:{
    open(){
      this.dialog = true
    }
  }
}
</script>