<template>
  <div>
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="primary"
          class="px-0 py-0"
          v-bind="attrs"
          v-on="on"
          :ripple="false"
          > {{$t("action.send_notice")}}</v-btn
        >
      </template>
      <SendMessage  :item="item" />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import SendMessage from "@/components/Moderator/Festival/Notice/Message";
export default {
  components: {
    SendMessage,
  },
  computed: mapState({
    //
  }),
  props: ["item"],
  data: () => ({
    dialog: false,
  }),
  created() {
    //
  },
  mounted() {
  },
  methods: {
   
  },
};
</script>