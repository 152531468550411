<template>
  <div>
    <v-dialog v-model="dialog" width="500">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="primary"
          class="px-0 py-0"
          v-bind="attrs"
          v-on="on"
          :ripple="false"
          @click="sendInvitation"
          >{{$t("action.send_invitation")}}</v-btn
        >
      </template>
      <v-card>
        <v-card-title>{{$t("action.send_invitation")}}</v-card-title>
        <v-card-text class="my-0 py-0">
          <School
            :callbackSelect="selectSchool"
            :schools="item.FestivalSchool"
          />
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="primary"
            text
            :disabled="lang == 'ms'"
            @click="changeLanguage('ms')"
          >
            Bahasa Melayu
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="lang == 'zh'"
            @click="changeLanguage('zh')"
          >
            中文
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="lang == 'en'"
            @click="changeLanguage('en')"
          >
            English
          </v-btn>
          <v-btn
            color="primary"
            text
            :disabled="lang == 'zh_ms'"
            @click="changeLanguage('zh_ms')"
          >
            双语（中/马来）
          </v-btn>
        </v-card-actions>
        <v-card-text>
          <v-textarea
            outlined
            v-model="translatedMessage"
            :label="$t('model.prop.message')"
            :value="translatedMessage"
          ></v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text :disabled="copied" @click="copy">
            <span v-if="copied">{{ $t("action.copied") }}</span>
            <span v-else>{{ $t("action.copy") }}</span>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import School from "@/components/Moderator/Festival/Notice/School.vue";
export default {
  components: {
    School,
  },
  computed: mapState({
    //
  }),
  props: ["item"],
  data: () => ({
    lang: "zh_ms",
    dialog: false,
    message: {
      en: "",
      zh: "",
      ms: "",
      zh_ms: "",
    },
    translatedMessage: "",
    copied: false,
    selectedSchool: null,
    schoolYears: null,
  }),
  created() {
    //
  },
  mounted() {},
  watch: {
    students: function (val) {
      this.translatedMessage = val;
    },
  },
  methods: {
    selectSchool(school) {
      this.selectedSchool = school;
        this.sendInvitation();
    },
    sendInvitation() {
      this.message = {
        en: `${this.selectedSchool.School.name} invited you to join to ${this.item.name}. School Code：${this.selectedSchool.School.code}. Join now.\n${process.env.VUE_APP_URL_GAME}?code=${this.selectedSchool.School.code}&lang=en`,
        zh: `${this.selectedSchool.School.name} 邀请您参加 ${this.item.name}。学校代码：${this.selectedSchool.School.code}。马上开始。${process.env.VUE_APP_URL_GAME}?code=${this.selectedSchool.School.code}&lang=zh`,
        ms: `${this.selectedSchool.School.name} menjemput anda menghadiri ${this.item.name}. Kod: ${this.selectedSchool.School.code}. Sertai sekarang.\n${process.env.VUE_APP_URL_GAME}?code=${this.selectedSchool.School.code}&lang=ms`,
        zh_ms:`${this.selectedSchool.School.name} 邀请您参加 ${this.item.name}。学校代码：${this.selectedSchool.School.code}。马上开始。${process.env.VUE_APP_URL_GAME}?code=${this.selectedSchool.School.code}&lang=zh\n\n${this.selectedSchool.School.name} menjemput anda menghadiri ${this.item.name}. Kod: ${this.selectedSchool.School.code}. Sertai sekarang.\n${process.env.VUE_APP_URL_GAME}?code=${this.selectedSchool.School.code}&lang=ms`
      };
      this.translatedMessage = this.message[this.lang];
    },
    changeLanguage(lang) {
      this.lang = lang;
      this.translatedMessage = this.message[this.lang];
      this.copied = false;
    },
    copy() {
      navigator.clipboard.writeText(this.translatedMessage);
      this.copied = true;
      var that = this;
      setTimeout(() => {
        if (that && that.copied) that.copied = false;
      }, 800);
    },
  },
};
</script>