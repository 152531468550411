<template>
  <div>
    <v-menu bottom origin="center center" transition="scale-transition">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          outlined
          rounded
          color="primary"
          v-bind="attrs"
          v-on="on"
        >
          Share Invitation
        </v-btn>
      </template>

      <v-list width="180px">
        <v-list-item>
          <v-list-item-action class="text-center">
            <ShareNetwork
              class="text-decoration-none"
              network="whatsapp"
              title="Game Invitation"
              :url="invitationUrl"
            >
              <v-btn width="150px" rounded color="green" dark>
                <v-icon left dark> mdi-whatsapp </v-icon>
                Whatsapp
              </v-btn>
            </ShareNetwork>
          </v-list-item-action>
        </v-list-item>
        <v-list-item>
          <v-list-item-action
            ><v-btn
              width="150px"
              :disabled="copied"
              @click="copy"
              rounded
              color="primary"
            >
              <v-icon left dark> mdi-content-copy </v-icon>
              <span v-if="copied">Copied</span>
              <span v-else>Copy</span>
            </v-btn></v-list-item-action
          >
        </v-list-item>
      </v-list>
    </v-menu>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {},
  computed: mapState({
    //
  }),
  props: ["password", "mobile"],
  data: () => ({
    copied: false,
    invitationUrl: null,
  }),
  created() {
    //
  },
  mounted() {
    this.invitationUrl = `${process.env.VUE_APP_URL_WEB_SCHOOL}/zh/login?m=%2B${this.mobile.slice(1)}&p=${this.password}`;
  },
  methods: {
    copy() {
      navigator.clipboard.writeText(this.invitationUrl);
      this.copied = true;
      var self = this;
      setTimeout(() => {
        if (self && self.copied) self.copied = false;
      }, 800);
    },
  },
};
</script>