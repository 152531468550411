<template>
  <v-btn v-if="account.id == item.id" text disabled color="success">{{$t("action.selected")}}</v-btn>
  <v-btn v-else text color="primary" @click="select()">{{$t("action.select")}}</v-btn>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      account: state => state.account.data,
    }),
    props:[
      'item',
      'role',
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      select() {
        this.$store.commit("updateAccount",this.item)
        this.$router.push({name:'Page'+this.role+'Dashboard'})
      },
    }
  }
</script>