<template>
  <div>
    <v-card outlined>
      <v-card-title>{{$t("view.PageBackendEvent.send_pass")}}</v-card-title>
      <v-card-actions>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'ms'"
          @click="changeLanguage('ms')"
        >
          Bahasa Melayu
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'zh'"
          @click="changeLanguage('zh')"
        >
          中文
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'en'"
          @click="changeLanguage('en')"
        >
          English
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'zh_ms'"
          @click="changeLanguage('zh_ms')"
        >
          双语（中/马来）
        </v-btn>
      </v-card-actions>
      <v-card-text>
        <ALoader :isLoading="loding"></ALoader>
        <img class="mb-3 shadow" width="100%" :src="output" />
        <div class="notice-image">
          <div class="notice" ref="printMe">
            <div class="notice-title text-uppercase d-flex align-center justify-center">
              <div v-if="item.School.logoUrl" class="mr-1">
                <img height="40" crossorigin="anonymous" :src="item.School.logoUrl"/>
              </div>
              <div>{{ {"en":`${item.School.name} INVITATION`,"ms":`JEMPUTAN ${item.School.name} `,"zh":`${item.School.name} 邀请函`,"zh_ms":`${item.School.name} 邀请函/JEMPUTAN`}[lang] }}</div>
            </div>
            <div class="notice-body">
              <img
                class="ma-1"
                :src="require('@/assets/Subject/Math.png')"
                width="300"
                style="position:absolute;bottom:0;right:0;"
              />
              <img
                class="ma-1"
                :src="require('@/assets/CompanyTitle.png')"
                width="200"
                style="position:absolute;bottom:0;left:0;"
              />
            </div>
            <v-row align="center" class="mx-0 blue-grey lighten-5">
              <v-col cols="9" class="text-left notice-message">
                <div
                  v-for="(item, i) in noticeMessage"
                  :key="i"
                >
                  <span v-if="i == 0" style="font-size:150%;"><b>{{ item }} <br /></b></span>
                  <span v-else>{{ item }} <br /></span>
                </div>
              </v-col>
              <v-col cols="3" class="text-right">
                <div class="qr-section">
                  <canvas class="qr-code" id="qr"></canvas>
                  <div class="font-weight-black black--text text-center">
                    {{ {"en":"CODE","ms":"KOD","zh":"学校代码","zh_ms":"学校代码/KOD"}[lang] }}: {{ item.School.code }}
                  </div>
                </div>
              </v-col>
            </v-row>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="downloadImg">{{$t("action.download")}}</v-btn>
        </v-card-actions>
        <div class="pt-6">
          <v-textarea
            outlined
           :label='$t("model.prop.message")'
            v-model="translatedMessage"
          ></v-textarea>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueHtml2Canvas from "vue-html2canvas";
import QRious from "qrious";
import Vue from "vue";
Vue.use(VueHtml2Canvas);
export default {
  components: {},
  computed: mapState({
    //
  }),
  props: ["message", "item", "lang"],
  data: () => ({
    loding: false,
    output: null,
    translatedMessage: "",
    noticeMessage: [],
  }),
  watch: {
    translatedMessage: function (val) {
      this.translatedMessage = val;
      this.noticeMessage = val.split("\n");
      if (!this.loding) {
        this.printThis();
      }
    },
  },
  created() {
    //
  },
  mounted() {
    this.reprocess();
    this.loding = true;
  },
  methods: {
    reprocess() {
      new QRious({
        element: document.getElementById("qr"),
        value: `${process.env.VUE_APP_URL_GAME}?code=${this.item.School.code}&lang=${(this.lang == 'zh_ms') ? 'zh' : this.lang}`,
      });
      this.translatedMessage = this.message[this.lang];
      this.noticeMessage = this.translatedMessage.split("\n");
      setTimeout(() => {
        this.printThis();
        this.loding = false;
      }, 1000);
    },
    async printThis() {
      const el = this.$refs.printMe;

      const options = {
        type: "dataURL",
        useCORS: true,
      };
      this.output = await this.$html2canvas(el, options);
    },
    downloadImg() {
      var link = document.createElement("a");
      link.download = "event-pass.png";
      link.href = this.output;
      link.click();
    },
    changeLanguage(lang) {
      this.lang = lang;
      this.reprocess()
    },
  },
};
</script>
<style scoped>
.game-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.notice {
  width: 640px;
  border: solid thin black;
  overflow: hidden;
}
.notice-title {
  color: black;
  font-size: 20px;
  padding:10px;
  text-align: center;
}
.notice-body {
  background: url("../../../../assets/Game/Banner/FISH.png") no-repeat center
    center fixed !important;
  width: 640px;
  height: 246px;
  position: relative;
}
.notice-image {
  height: 0;
  width: 0;
  overflow: hidden;
}
.qr-code {
  width: 100%;
}

.notice-message {
  color: black;
  padding:30px !important;
  font-size: 20px;
  line-height: 30px;
}
</style>