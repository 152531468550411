<template>
<v-chip 
  :color="(value == null) ? 'grey' : ((value) ? 'success' : 'error')" 
  dark
  small
>{{(value == null) ? '・・・' : ((value) ? textTrue : textFalse)}}</v-chip>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:{
      value:{
        type:Boolean,
        default:null,
      },
      textTrue:{
        type:String,
        default:'Yes',
      },
      textFalse:{
        type:String,
        default:'No',
      },
    },
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>