var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-btn',{attrs:{"large":"","to":_vm.$_getRouteGrandChildEdit(
      _vm.role,
      _vm.model.key,
      _vm.$route.params.id,
      _vm.me,
      _vm.modelParent.key,
      _vm.$route.params.parentId,
      _vm.parent,
      _vm.modelGrandParent.key,
      _vm.grandParent.id,
      _vm.grandParent
    )}},[_vm._v(" "+_vm._s(_vm.$t("action.edit"))+" ")])}
var staticRenderFns = []

export { render, staticRenderFns }