<template>
  <v-row>
    <v-col
      ><v-select
        dense
        :label="$t('model.name.school')"
        :placeholder="$t('model.name.school')"
        class="white"
        :items="schools"
        item-text="School.name"
        outlined
        v-model="schoolId"
        item-value="schoolId"
      ></v-select
    ></v-col>
  </v-row>
</template>

<script>
export default {
  props: ["callbackSelect", "schools"],
  data: () => ({
    schoolId: null,
    item: null,
    selectedSchool: {},
  }),
  watch: {
    schoolId: function (val) {
      this.selectedSchool = this.$_.find(this.schools, function (obj) {
          return  obj.schoolId == val
      });
     this.callbackSelect(this.selectedSchool);
    },
  },
  created() {
    this.item = this.schools[0];
    this.schoolId = this.schools[0].schoolId;
  },
};
</script>

<style>
</style>