<template>
  <v-text-field
    v-model="value"
    type="text"
   :label='$t("model.prop.password")'
    :rules="rules"
    counter
    maxlength="4"
  ></v-text-field>
</template>

<script>
import i18n from '@/plugins/i18n'
export default {
  props: ["callbackSelect", "callbackError", "value"],
  data: () => ({
    rules: [(v) => (v && v.length == 4) || i18n.t("rules.password")],
  }),
  watch: {
    value: function (val) {
     this.value = val.toUpperCase()
      this.callbackSelect(val);
    },
  },
  methods:{
    
  },
};
</script>

<style>
</style>