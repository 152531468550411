<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title>Are you sure you want to destroy <b class="mx-1 error--text">{{(item.name) ? item.name : `${model.name.singular} ${item.id}`}}</b>?</v-card-title>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="close">{{$t("action.cancel")}}</v-btn>
        <v-btn color="error darken-1" text @click="confirm" :loading="api.isLoading">{{$t("action.ok")}}</v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      'role',
      'model',
    ],
    data: () => ({
      //BOC:[api]
      api:{
        isLoading:false,
        isError:false,
        error:null,
        url:null,
      },
      //EOC
      item:{},
      dialog:false,
      url:null,
    }),
    created() {
      //BOC:[url]
      this.url = `${this.$api.servers.admin}/v1/bread/${this.$route.params.object}/destroy`
      //EOC
      //BOC:[api]
      this.api.url = this.url
      this.api.callbackReset = () => {
        this.api.isLoading = true
        this.api.isError = false
        this.api.error = null
      }
      this.api.callbackError = (e) => {
        this.api.isLoading = false
        this.api.isError = true
        this.api.error = e
      }
      this.api.callbackSuccess = () => {
        this.api.isLoading = false
        this.$router.push({name:'PageAdminDataBrowseRedirect',params:{object:this.$route.params.object}})
      }
      //EOC
    },
    mounted() {
      //
    },
    methods: {
      open(item) {
        this.item = item
        this.dialog = true
      },
      close() {
        this.dialog = false
      },
      confirm() {
        this.api.params = this.$_.clone({id:this.item.id})
        this.$api.fetch(this.api)
      },
    }
  }
</script>