<template>
  <div>
    <v-form ref="form" lazy-validation @submit.prevent="validate">
      <v-row class="pt-3">
        <v-file-input
          v-model="logo"
          show-size
          :rules="rules"
          accept="image/png, image/jpeg, image/bmp"
          :placeholder="model.key == 'Institution' ? $t('string.pick_logo'): 'Pick a image'"
          prepend-icon="mdi-camera"
          :label="model.key == 'Institution' ? $t('model.prop.logo'): 'Image'"
        ></v-file-input>
        <div v-if="logo">
          <v-btn
            v-if="logo.length != 0"
            class="ml-3"
            fab
            :loading="api.isLoading"
            dark
            small
            color="primary"
            @click="submit"
          >
            <v-icon dark> mdi-check </v-icon>
          </v-btn>
        </div>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import axios from "axios";
import store from "../../../store";
import i18n from '@/plugins/i18n'
export default {
  props: ["callbackSuccess", "callbackPreview","model"],
  data: () => ({
    logo: [],
    url: null,
    selectedFile: null,
    rules: [
      (value) =>
        !value ||
        value.size < 2000000 ||  i18n.t("rules.logo"),
    ],
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC
    form: {},
  }),
  created() {
    //BOC:[api]
    this.api.url =
      this.$api.servers[this.model.server] +
      "/api/v1/en/moderator/"+this.model.key.toLowerCase()+"/" +
      this.$route.params.id +
      "/logo";
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.api.isLoading = false;
      this.callbackSuccess(resp);
    };
    //EOC
  },
  watch: {
    logo: function (val) {
      this.logo = val;
      if (val) {
        this.url = URL.createObjectURL(val);
      } else {
        this.url = null;
      }

      this.callbackPreview(this.url);
    },
  },
  methods: {
    //BOC:[form]
    validate() {
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.isLoading = true;
      const formData = new FormData();
      const token = "Bearer " + store.state.auth.data.Session.token;
      const config = {
        headers: { Authorization: token },
      };
      formData.append("logo", this.logo);
      axios
        .post(
          this.$api.servers[this.model.server] +
            "/api/v1/en/moderator/"+this.model.key.toLowerCase()+"/" +
            this.$route.params.id +
            "/logo",
          formData,
          config,
          {}
        )
        .then((response) => {
          this.api.isLoading = false;
          this.callbackSuccess(response, this.url);
        });
      // this.api.params = this.$_.clone(formData);
      // this.$api.fetch(this.api);
    },
    //EOC
  },
};
</script>

<style>
</style>