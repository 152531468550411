<template>
  <div>
    <div v-if="item[objectKey] != null">
      <div v-if="$_hasRoute({name:'Page'+role+objectKey+'Read',params:{id:item[objectKey].id}})">
        <router-link  class="text-decoration-none" :to="{name:'Page'+role+objectKey+'Read',params:{id:item[objectKey].id}}">
          <span v-if="item[objectKey].translatableName">{{JSON.parse(item[objectKey].translatableName)[$_getLocale()]}}</span>
          <span v-else-if="item[objectKey].name">{{item[objectKey].name}}</span>
          <span v-else>{{item[objectKey].id}}</span>
        </router-link>
      </div>
     <div v-else >
       <router-link v-if="objectKey == 'Chapter'" class="text-decoration-none" :to="{name:'PageMainDiscover',query:{s:item[objectKey].syllabusId,c:item[objectKey].id}}" target="_blank">
        <span v-if="item[objectKey].translatableName"> <span>{{item[objectKey].sort }}. </span> {{JSON.parse(item[objectKey].translatableName)[$_getLocale()]}}</span>
      </router-link>
       <router-link v-else-if="objectKey == 'Question'" class="text-decoration-none" :to="{name:'PageMainDiscoverSample',params:{chapterId:item['Chapter'].id, code:item[objectKey].key}}" target="_blank">
        <span v-if="item[objectKey].translatableName"> {{JSON.parse(item[objectKey].translatableName)[$_getLocale()]}}</span>
      </router-link>
<router-link v-else class="text-decoration-none" :to="{name:'PageAdminDataRead',params:{id:item[objectKey].id,object:$_lcfirst(objectKey)}}">
        <span v-if="item[objectKey].translatableName"> {{JSON.parse(item[objectKey].translatableName)[$_getLocale()]}}</span>
        <span v-else-if="item[objectKey].name">{{item[objectKey].name}}</span>
        <span v-else>{{`${objectKey} ${item[objectKey].id}`}}</span>
      </router-link>
     </div>
      
    </div>
    <span v-else>-</span>
  </div>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    components:{
      //
    },
    computed: mapState({
      //
    }),
    props:[
      'objectKey',
      'item',
      'role',
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
    }
  }
</script>