<template>
  <v-row justify="center">
    <v-btn v-if="password" @click.stop="dialog = true" text color="primary">
      {{ password }}
      <v-icon right dark> mdi-lock-outline </v-icon>
    </v-btn>
    <v-btn v-else @click.stop="dialog = true" text color="primary">
       {{$t("action.set_password")}}
      <v-icon right dark>mdi-lock-open-variant-outline</v-icon>
    </v-btn>
    <v-dialog v-model="dialog" max-width="290">
      <v-card :loading="api.isLoading">
        <v-card-title class="text-h6">
         {{$t("view.PageBackendEvent.set_password")}}
          <v-spacer></v-spacer>
          <v-btn icon @click="dialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-form ref="form" lazy-validation @submit.prevent="validate">
          <v-card-text>
            <FormInput :callbackSelect="callbckSelect" :value="value" />
          </v-card-text>

          <v-card-actions>
            <v-spacer></v-spacer>

            <v-btn
              color="error darken-1"
              :disabled="!password"
              text
              @click="remove"
            >
               {{$t("action.remove")}}
            </v-btn>

            <v-btn color="primary darken-1" text @click="validate">
               {{$t("action.save")}}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-row>
</template>
<script>
import FormInput from "@/components/Bread/Event/Password/FormInput";
export default {
  data() {
    return {
      dialog: false,
      formPassword: "",
      password:"",
      //BOC:[api]
      api: {
        isLoading: false,
        isError: false,
        error: null,
        url: null,
      },
      //EOC
    };
  },
  props: ["eventId", "value"],
  components: {
    FormInput,
  },
  created() {
    //BOC:[api]
    this.api.url = `${this.$api.servers.backend}/v1/event/${this.$route.params.id}/password/add`;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = (resp) => {
      this.password = resp.password;
      this.value = resp.password;
      this.$emit("refetch");
      this.api.isLoading = false;
      this.dialog = false;
    };
    //EOC
  },
  methods: {
    callbckSelect(password) {
      this.formPassword = password;
    },
    //BOC:[form]
    validate() {
      this.value = this.formPassword;
      this.api.callbackReset();
      if (this.$refs.form.validate()) {
        this.submit();
      } else {
        this.api.callbackError("Please check the form.");
      }
    },
    submit() {
      this.api.params = this.$_.clone({ password: this.formPassword });
      this.$api.fetch(this.api);
    },
    remove() {
      this.api.params = this.$_.clone({ password: null });
      this.$api.fetch(this.api);
    },
    //EOC
  },
  mounted() {
    this.formPassword = this.value;
    this.password = this.value
  },
};
</script>

<style>
</style>