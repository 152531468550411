<template>
  <v-dialog v-model="dialog" max-width="500px">
    <v-card>
      <v-card-title
        >Are you sure you want to remove
        <b v-if="model.key == 'EventSchoolYear'" class="mx-1 error--text">Year {{ item.schoolYear }}</b
        >
        ?</v-card-title
      >
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="gray darken-1" text @click="close">{{$t("action.cancel")}}</v-btn>
        <v-btn
          color="error darken-1"
          text
          @click="confirm"
          :loading="api.isLoading"
          >{{$t("action.ok")}}</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>


//EOC
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: ["model"],
  data: () => ({
    key: false,
  
    //EOC
    //BOC:[api]
    api: {
      isLoading: false,
      isError: false,
      error: null,
      url: null,
    },
    //EOC

    form: {},
    dialog: false,
    url: null,
  }),
  created() {
    //BOC:[url]
    if (this.model.key == "EventSchoolYear") {
      this.url = `${this.$api.servers.event}/api/v1/en/moderator/event/${this.$route.params.id}/schoolYear/delete`;
    }
    //EOC
    //BOC:[api]
    this.api.url = this.url;
    this.api.callbackReset = () => {
      this.api.isLoading = true;
      this.api.isError = false;
      this.api.error = null;
    };
    this.api.callbackError = (e) => {
      this.api.isLoading = false;
      this.api.isError = true;
      this.api.error = e;
    };
    this.api.callbackSuccess = () => {
      this.api.isLoading = false;
      this.dialog = false;
      this.$emit("refetch");
    };
    //EOC
  },
  mounted() {
    //
  },
  methods: {
    open(item) {
      this.item = item;
      this.dialog = true;
    },
    close() {
      this.dialog = false;
    },
    confirm() {
      if (this.model.key == "EventSchoolYear") {
      this.api.params = this.$_.clone({ id: this.item.id });
    }
      this.$api.fetch(this.api);
    },
  },
};
</script>