<template>
  <div>
    <v-dialog v-model="dialog" width="600">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          text
          color="primary"
          class="px-0 py-0"
          v-bind="attrs"
          v-on="on"
          :ripple="false"
          @click="sendInvitation"
          >{{$t("action.send_pass")}}</v-btn
        >
      </template>
      <DownloadImage
        v-if="renderComponent"
        :lang="lang"
        :message="imageMessage"
        :item="item"
      />
    </v-dialog>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DownloadImage from "@/components/Bread/Event/Pass/DownloadImage";
import moment from "moment";
export default {
  components: {
    DownloadImage,
  },
  computed: mapState({
    //
  }),
  props: ["item"],
  data: () => ({
    lang: null,
    dialog: false,
    renderComponent: true,
    message: {
      en: "",
      zh: "",
      ms: "",
    },
    imageMessage: {
      en: "",
      zh: "",
      ms: "",
    },
  }),
  created() {
    //this.lang = this.$_getLocale();
    this.lang = "zh_ms";
  },
  mounted() {
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;

      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
   
    sendInvitation() {
   
      this.imageMessage = {
        en: `${this.item.name}\n\nFrom ${moment(
          this.item.timestampStart
        ).format("MMMM Do YYYY hh:mm A")}\nTo ${moment(
          this.item.timestampEnd
        ).format(
          "MMMM Do YYYY hh:mm A"
        )} \n\n Who will catch the legendary fish?`,
        zh:""
          +`${this.item.name}`
          +`\n\n开始：${moment(this.item.timestampStart).locale('zh_cn').format("LLL")}`
          +`\n结束：${moment(this.item.timestampEnd).locale('zh_cn').format("LLL")}`
          +`\n\n对象：一年级。`
          +`\n\n有谁可以钓到传说中的鱼儿呢？`
        ,
        ms:""
          +`Pertandingan Matematik Online Peringkat Sekolah`
          +`\n\nMulai ${moment(this.item.timestampStart).locale('ms').format("LLLL")}`
          +`\nSampai ${moment(this.item.timestampEnd).locale('ms').format("LLLL")}`
          +`\nUntuk murid tahun 1 sahaja.`
          +`\n\nBolehkah kamu menangkap ikan lagendaris?`
        ,
        zh_ms:""
          +`${this.item.name}`
          +`\n开始：${moment(this.item.timestampStart).locale('zh_cn').format("LLLL")}`
          +`\n结束：${moment(this.item.timestampEnd).locale('zh_cn').format("LLLL")}`
          // +`\n对象：一年级。`
          // +`\n有谁可以钓到传说中的鱼儿呢？`
          +`\n\nPertandingan Matematik Peringkat Sekolah`
          +`\nMulai ${moment(this.item.timestampStart).locale('ms').format("LLLL")}`
          +`\nSampai ${moment(this.item.timestampEnd).locale('ms').format("LLLL")}`
          // +`\nUntuk murid tahun 1 sahaja.`
          // +`\nSiapakah yang dapat menangkapkan ikan lagendaris?`
        ,
      };
    
    },
  },
};
</script>