<template>
  <v-btn
    large
    :to="
      $_getRouteGrandChildEdit(
        role,
        model.key,
        $route.params.id,
        me,
        modelParent.key,
        $route.params.parentId,
        parent,
        modelGrandParent.key,
        grandParent.id,
        grandParent
      )
    "
  >
    {{ $t("action.edit") }}
  </v-btn>
</template>

<script>
import { mapState } from "vuex";
export default {
  components: {
    //
  },
  computed: mapState({
    //
  }),
  props: [
    "me",
    "parent",
    "grandParent",
    "role",
    "model",
    "modelParent",
    "modelGrandParent",
  ],
  data: () => ({
    //
  }),
  created() {
    //
  },
  mounted() {
    //
  },
  methods: {
    //
  },
};
</script>