<template>
  <div>
     <ALoader :isLoading="!renderComponent"></ALoader>
    <v-card outlined>
      <v-card-title>{{$t("view.PageBackendEvent.send_event_notice")}}</v-card-title>
      <v-card-actions>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'ms'"
          @click="changeLanguage('ms')"
        >
          Bahasa Melayu
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'zh'"
          @click="changeLanguage('zh')"
        >
          中文
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'en'"
          @click="changeLanguage('en')"
        >
          English
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'zh_ms'"
          @click="changeLanguage('zh_ms')"
        >
          双语（中/马来）
        </v-btn>
      </v-card-actions>
      <v-tabs>
        <!-- <v-tab><span  @click="forceRerender">{{$t("view.PageBackendEvent.image")}}</span> </v-tab> -->
        <v-tab>{{$t("view.PageBackendEvent.message")}}</v-tab>
        <!-- <v-tab-item>
          <DownloadImage
            v-if="renderComponent"
            :lang="lang"
            :message="translatedImageMessage"
            :item="item"
          />
        </v-tab-item> -->
        <v-tab-item>  <v-card-text>
            <v-textarea
              outlined
              :label='$t("model.prop.message")'
              :value="translatedMessage"
              v-model="translatedMessage"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :disabled="copied" @click="copy">
              <span v-if="copied">{{$t("action.copied")}}</span>
              <span v-else>{{$t("action.copy")}}</span>
            </v-btn>
          </v-card-actions> </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
// import DownloadImage from "@/components/Bread/Event/Notice/DownloadImage";
import moment from "moment";
export default {
  components: {
    // DownloadImage,
  },
  computed: mapState({
    //
  }),
  props: ["item", "callbackLang"],
  data: () => ({
     message: {
      en: "",
      zh: "",
      ms: "",
    },
    imageMessage: {
      en: "",
      zh: "",
      ms: "",
    },
    lang: "",
    translatedMessage: "",
     translatedImageMessage: "",
    copied: false,
    renderComponent: true,
  }),
  created() {
    //
  },
  mounted() {
    //this.lang = this.$_getLocale();
    this.lang = "zh";
    this.sendInvitation()
  },
  methods: {
     forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    changeLanguage(lang) {
      this.lang = lang;
      this.translatedMessage = this.message[this.lang];
     this.sendInvitation()
      this.copied = false;
    },
    copy() {
      navigator.clipboard.writeText(this.translatedMessage);
      this.copied = true;
      var that = this;
      setTimeout(() => {
        if (that && that.copied) that.copied = false;
      }, 800);
    },
     sendInvitation() {
      this.message = {
        en: `Hamochi\nWe are going to have school online Mathematics game contest for lower school students from ${moment(
          this.item.timestampStart
        ).locale('en').format("MMMM Do YYYY hh:mm A")} to ${moment(
          this.item.timestampEnd
        ).locale('en').format("MMMM Do YYYY hh:mm A")} (${moment(
          this.item.timestampEnd
        ).diff(
          moment(this.item.timestampStart),
          "days"
        )} days). Details will be released on first day of the contest. This game is provided by hamochi. `,
        zh: `Hamochi\n比赛将从 ${moment(
          this.item.timestampStart
        ).locale('zh_cn').format("MMMM Do YYYY hh:mm A")} 分结束 ${moment(
          this.item.timestampEnd
        ).locale('zh_cn').format("MMMM Do YYYY hh:mm A")} (为期${moment(
          this.item.timestampEnd
        ).diff(
          moment(this.item.timestampStart),
          "days"
        )} 天). 详情有待公布，敬请期待！此游戏是hamochigames为您呈现。 `,
        ms: `Hamochi\nKami akan ada pertandingan Matematik online peringkat sekolah dengan permainan untuk murid tahun rendah dari ${moment(
          this.item.timestampStart
        ).locale('ms').format("MMMM Do YYYY hh:mm A")} sehingga ${moment(
          this.item.timestampEnd
        ).locale('ms').format("MMMM Do YYYY hh:mm A")} (${moment(
          this.item.timestampEnd
        ).diff(
          moment(this.item.timestampStart),
          "days"
        )} hari). Maklumat lanjut akan diberitahu pada hari pertandingan. `,
      };

      this.imageMessage = {
        en: `School Online Mathematics Game Contest\n\nFrom ${moment(
          this.item.timestampStart
        ).locale('en').format("MMMM Do YYYY hh:mm A")}\nTo ${moment(
          this.item.timestampEnd
        ).locale('en').format(
          "MMMM Do YYYY hh:mm A"
        )} \nFor year 1 students only.\n\nDetails will be released on the first day of the contest.`,
        zh:""
          +`${this.item.name}`
          +`\n\n香甜可口，迎合小学生口味的线上数学游戏比赛就要到来了～`
          +`\n\n开始：${moment(this.item.timestampStart).locale('zh_cn').format("LLLL")}`
          +`\n结束：${moment(this.item.timestampEnd).locale('zh_cn').format("LLLL")}`
          +`\n对象：一年级。`
          +`\n\n比赛当日会公布详情和发送邀请函。敬请期待！`,
        ms:""
          +`Pertandingan Matematik Online Peringkat Sekolah`
          +`\n\nDari ${moment(this.item.timestampStart).locale('ms').format("LLLL")}`
          +`\nSehingga ${moment(this.item.timestampEnd).locale('ms').format("LLLL")}`
          +`\nUntuk murid tahun 1 sahaja.`
          +`\n\nMaklumat lanjut akan diberitahu pada hari pertandingan.`,
        zh_ms:""
          +`${this.item.name}`
          +`\nPertandingan Matematik Peringkat Sekolah`
          +`\n\n香甜可口，迎合小学生口味的线上数学游戏比赛就要到来了～`
          +`\nPertandingan matematik online yang sesuai untuk murid-murid akan diadakan.`
          +`\n\n开始：${moment(this.item.timestampStart).locale('zh_cn').format("LLLL")}`
          +`\n结束：${moment(this.item.timestampEnd).locale('zh_cn').format("LLLL")}`
          +`\n对象：一年级。`
          +`\n\nMulai ${moment(this.item.timestampStart).locale('ms').format("LLLL")}`
          +`\nSampai ${moment(this.item.timestampEnd).locale('ms').format("LLLL")}`
          +`\nUntuk murid tahun 1 sahaja.`
          +`\n\n比赛当日会公布详情和发送邀请函。敬请期待！`
          +`\nMaklumat lanjut akan diberitahu pada hari pertandingan.`,
      };
      
    this.translatedMessage = this.message[this.lang];
     this.translatedImageMessage = this.imageMessage[this.lang];
    },
  },
   watch: {
		students: function (val) {
			this.translatedMessage = val;
		},
	},
};
</script>