<template>
  <div>
    <ALoader :isLoading="!renderComponent"></ALoader>
    <v-card outlined>
      <v-card-title> Send Festival Notice</v-card-title>
      <v-card-text class="my-0 py-0">
        <School :callbackSelect="selectSchool" :schools="item.FestivalSchool" />
      </v-card-text>
      <v-card-actions>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'ms'"
          @click="changeLanguage('ms')"
        >
          Bahasa Melayu
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'zh'"
          @click="changeLanguage('zh')"
        >
          中文
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'en'"
          @click="changeLanguage('en')"
        >
          English
        </v-btn>
        <v-btn
          color="primary"
          text
          :disabled="lang == 'zh_ms'"
          @click="changeLanguage('zh_ms')"
        >
          双语（中/马来）
        </v-btn>
      </v-card-actions>
      <v-tabs>
        <v-tab
          ><span @click="forceRerender">{{
            $t("view.PageBackendEvent.image")
          }}</span>
        </v-tab>
        <v-tab>{{ $t("view.PageBackendEvent.message") }}</v-tab>
        <v-tab-item>
          <DownloadImage
            v-if="renderComponent"
            :school="selectedSchool"
            :lang="lang"
            :message="translatedImageMessage"
            :item="item"
          />
        </v-tab-item>
        <v-tab-item>
          <v-card-text>
            <v-textarea
              outlined
              :label="$t('model.prop.message')"
              :value="translatedMessage"
               v-model="translatedMessage"
            ></v-textarea>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="primary" text :disabled="copied" @click="copy">
              <span v-if="copied">{{ $t("action.copied") }}</span>
              <span v-else>{{ $t("action.copy") }}</span>
            </v-btn>
          </v-card-actions>
        </v-tab-item>
      </v-tabs>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import DownloadImage from "@/components/Moderator/Festival/Notice/DownloadImage";
import moment from "moment";
import School from "./School.vue";
export default {
  components: {
    DownloadImage,
    School,
  },
  computed: mapState({
    //
  }),
  props: ["item", "callbackLang"],
  data: () => ({
    message: {
      en: "",
      zh: "",
      ms: "",
      zh_ms: "",
    },
    imageMessage: {
      en: "",
      zh: "",
      ms: "",
    },
    trailerUrl: {
      en: "",
      zh: "",
      ms: "",
    },
    lang: "",
    translatedMessage: "",
    translatedImageMessage: "",
    copied: false,
    renderComponent: true,
    selectedSchool: null,
    schoolYears:null,
  }),
  created() {
    //
  },
  mounted() {
    //this.lang = this.$_getLocale();
    this.lang = "zh";
  },
  methods: {
    forceRerender() {
      this.renderComponent = false;
      this.$nextTick(() => {
        this.renderComponent = true;
      });
    },
    selectSchool(school) {
      this.selectedSchool = school;
      this.forceRerender()
    this.sendInvitation();
    },
    changeLanguage(lang) {
      this.lang = lang;
      this.translatedMessage = this.message[this.lang];
      this.sendInvitation();
      this.copied = false;
    },
    copy() {
      navigator.clipboard.writeText(this.translatedMessage);
      this.copied = true;
      var that = this;
      setTimeout(() => {
        if (that && that.copied) that.copied = false;
      }, 800);
    },
    sendInvitation() {

    var schoolYearsEn = this.$_.map(this.item.Event, (el) => "Year " +el.schoolYear)
      var schoolYearsMs = this.$_.map(this.item.Event, (el) => "Tahun " +el.schoolYear)
        var schoolYearsZh = this.$_.map(this.item.Event, (el) => el.schoolYear+"年级")

        if(this.item.Event[0].Game.code == 'DASH'){
          this.trailerUrl={
          en:'https://youtu.be/zxPtKEuxeUE',
          zh:'https://youtu.be/t3dLSJ_OcfQ',
          ms:'https://youtu.be/dD2wqLyTeKA'
        }
        }else if(this.item.Event[0].Game.code == 'FISH'){
          this.trailerUrl={
          en:'https://youtu.be/8fSo6pSeFPU',
          zh:'https://youtu.be/VAKYg6wWt28',
          ms:'https://youtu.be/sHCtl1_bkZI'
        }
        }
      
      this.message = {
        en: `${this.item.name} \nWe are going to have school online Mathematics game contest for ${schoolYearsEn.toString()} students from ${moment(
         this.item.Event[0].timestampStart
        )
          .locale("en")
          .format("MMMM Do YYYY hh:mm A")} to ${moment(this.item.Event[0].timestampEnd)
          .locale("en")
          .format("MMMM Do YYYY hh:mm A")}. Students can learn while playing during school holidays. The school will share a login link on the first day. You can watch the trailer at ${this.trailerUrl['en']}. More information can be found at ${process.env.VUE_APP_URL_CLASSROOM}${this.lang}/festivals/${this.item.code}?s=${this.selectedSchool.School.code} `,
        zh: `*${this.item.name}*\n好消息！为巩固学生们的知识，${this.selectedSchool.School.name}将联同其他学校于${moment(this.item.Event[0].timestampStart).locale("zh_cn").format("LL（dddd）")}至${moment(this.item.Event[0].timestampEnd).locale("zh_cn").format("LL（dddd）")}为${schoolYearsZh.join('、')}的学生们举办Hamochi 线上数学活动。学生们可以在活动期间快乐地复习数学。校方会在活动第一天分享登入网址给学生们。欲知详情，可以查看活动预告片 ${this.trailerUrl['zh']} 和游览 ${process.env.VUE_APP_URL_CLASSROOM}zh/festivals/${this.item.code}?s=${this.selectedSchool.School.code}`,
        ms:  `${this.selectedSchool.School.name} akan mengadakan aktiviti matematik dalam talian bersama dengan sekolah-sekolah lain untuk murid-murid ${schoolYearsMs.join(', ')} dari ${moment(this.item.Event[0].timestampStart ) .locale("ms") .format("DD-MM-YYYY (dddd)")} sehingga ${moment( this.item.Event[0].timestampEnd) .locale("ms") .format("DD-MM-YYYY (dddd)")}. Murid-murid boleh belajar sambil bermain semasa cuti sekolah ini. Pihak sekolah akan kongsikan pautan akses pada hari pertama. Tengokkan treler di ${this.trailerUrl['ms']} . Maklumat lanjut boleh didapati di ${process.env.VUE_APP_URL_CLASSROOM}ms/festivals/${this.item.code}?s=${this.selectedSchool.School.code} `,
        zh_ms:`(NOT AVAILABLE)`
      };
      this.message.zh_ms = this.message.zh+'\n\n'+this.message.ms;

      this.imageMessage = {
        en:``,
        zh:`${this.item.name}`+
          `\n\n非一般的线上数学活动，益智又刺激!` +
          `\n\n开始：${moment(this.item.Event[0].timestampStart)
            .locale("zh_cn")
            .format("LL (dddd) a hh:mm")}` +
          `\n结束：${moment(this.item.Event[0].timestampEnd)
            .locale("zh_cn")
            .format("LL (dddd) a hh:mm")}` +
          `\n学年：${schoolYearsZh.join("、")}` +
          `\n地点：线上进行（可以使用个人电脑或手机参与）`+
          `\n参与方式：活动期间登入校方提供的网址即可`,
        ms:`${this.item.name}`+
          `\n\nAktiviti pembelajaran yang menarik!` +
          `\n\nDari ${moment(this.item.Event[0].timestampStart)
            .locale("ms")
            .format("DD-MM-YYYY (dddd) h a")}` +
          ` sehingga ${moment(this.item.Event[0].timestampEnd)
            .locale("ms")
            .format("DD-MM-YYYY (dddd) h a")}` +
          ` untuk pelajar ${schoolYearsMs.join(", ")}.` +
          ` Aktiviti akan diadakan secara atas talian dan boleh diakses melalui komputer atau telefon bimbit di mana-mana dan pada bila-bila masa.`+
          ` Pautan akses akan dikongsi pada hari pertama.`,
        zh_ms:``
        // zh_ms:
        //   "" +
        //   `${this.item.name}` +
        //   `\n\n香甜可口，迎合小学生口味的线上数学游戏比赛就要到来了～` +
        //   `\nPertandingan matematik online yang sesuai untuk murid-murid akan diadakan.` +
        //   `\n\n开始：${moment(this.item.Event[0].timestampStart)
        //     .locale("zh_cn")
        //     .format("LLLL")}` +
        //   `\n结束：${moment(this.item.Event[0].timestampEnd)
        //     .locale("zh_cn")
        //     .format("LLLL")}` +
        //   `\n学年：${schoolYearsZh.toString()}` +
        //   `\n\nMulai ${moment(this.item.Event[0].timestampStart)
        //     .locale("ms")
        //     .format("LLLL")}` +
        //   `\nSampai ${moment(this.item.Event[0].timestampEnd)
        //     .locale("ms")
        //     .format("LLLL")}` +
        //   `\nTahun Persekolahan: ${schoolYearsMs.toString()}` +
        //   `\n\n比赛当日会公布详情和发送邀请函。敬请期待！` +
        //   `\nMaklumat lanjut akan diberitahu pada hari pertandingan.`,
      };
       
      this.translatedMessage = this.message[this.lang];
      this.translatedImageMessage = this.imageMessage[this.lang];
    },
  },
   watch: {
		students: function (val) {
			this.translatedMessage = val;
		},
	},
};
</script>