<template>
  <v-card>
    <DataTable
      bread="read"
      :api="false"
      :items="[data]"
      :role="role"
      :model="model"
      search=""
      :headers="model[role].read.table.headers"
       @refetch="refetchInfo"
    ></DataTable>
  </v-card>
</template>

<script>
  import DataTable from '@/components/Bread/DataTable'
  import { mapState } from 'vuex'
  export default {
    components:{
      DataTable,
    },
    computed: mapState({
      //
    }),
    props:[
      'role',
      'model',
      'url',
      'data',
    ],
    data: () => ({
      //
    }),
    created() {
      //
    },
    mounted() {
      //
    },
    methods: {
      //
      refetchInfo(){
        this.$emit("refetch");
      }
    }
  }
</script>