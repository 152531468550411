<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <ALoader :isLoading="loding"></ALoader>
        <img class="mb-3 shadow" width="100%" :src="output" />
        <div class="notice-image">
          <div class="notice" ref="printMe">
            <div
              v-if="school && school.School"
              class="notice-title text-uppercase"
            >
              <div v-if="school.School.logoUrl" class="school-logo">
                <img
                  width="100%"
                  crossorigin="anonymous"
                  :src="school.School.logoUrl"
                />
              </div>
              <!-- <div>{{ {"en":`${school.School.name} NOTICE`,"ms":`NOTIS ${school.School.name} `,"zh":`${school.School.name} 通告`,"zh_ms":`${school.School.name} 通告/NOTIS`}[lang] }}</div> -->
            </div>

            <v-row>
              <v-col cols="12" class="text-left notice-message">
                <div v-for="(item, i) in noticeMessage" :key="i">
                  <div v-if="i == 0" class="text-h3 text-center font-weight-black">
                    {{ item }}
                  </div>
                  <div v-else-if="i == 2">
                    <span class="text-h4">{{ item }}</span>
                  </div>
                  <span v-else>{{ item }} <br /></span>
                </div>
              </v-col>
            </v-row>

            <v-row class="pt-6">
              <v-col cols="4" class="text-center">
                <div class="pr-1 text-center text-h6">{{ {"en":``,"ms":`Kutip semua bintang`,"zh":`获得全部的星星`,"zh_ms":``}[lang] }} <br> {{ {"en":``,"ms":``,"zh":`送你`,"zh_ms":``}[lang] }}<span class="purple--text"><b>{{ {"en":``,"ms":`untuk dapat LENCANA!`,"zh":`可爱的徽章!`,"zh_ms":``}[lang] }}</b></span></div>
                <img
                  class="pr-1"
                  :src="require('@/assets/Festival/notice/badges2.png')"
                  width="110"
                />
                <img
                  :src="require('@/assets/Festival/notice/badges1.png')"
                  width="110"
                />
              </v-col>
              <v-col cols="4" class="text-center">
                <div class="text-h5 font-weight-black black--text">{{ {"en":`Scan the QR Code`,"ms":`Imbas Kod QR`,"zh":`扫描QR查阅详情`,"zh_ms":`扫描QR查阅详情/Imbas Kod QR`}[lang] }}</div>
                <canvas class="qr-code my-0" id="pass"></canvas>   
              <div class="text-h6 font-weight-black black--text">{{ {"en":`School Code`,"ms":`Kod`,"zh":`学校代码`,"zh_ms":`学校代码/Kod Sekolah`}[lang] }}: {{school.School.code}}</div>
              </v-col>
              <v-col cols="4" class="text-center"> <div v-if="item.FestivalSponsor">
                <div class="text-h6">{{ {"en":`Many thanks to the sponsors.`,"ms":`Ribuan terima kasih kepada pihak penaja.`,"zh":`特别鸣谢各大赞助商`,"zh_ms":`特别鸣谢各大赞助商/Ribuan terima kasih kepada pihak penaja.`}[lang] }}</div>
                <div class="pa-3">
                  <img
                  style="marginright: 10px"
                  width="80%"
                  crossorigin="anonymous"
                  :src="item.FestivalSponsor[0].Sponsor.logoUrl"
                />
                </div>
                <div class="text-h6">
                  {{item.FestivalSponsor[0].Sponsor.name}}
                </div>
              </div> </v-col>
            </v-row>
          </div>
        </div>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="downloadImg"
            >{{ $t("action.download") }}
          </v-btn>
        </v-card-actions>
        <div class="pt-6">
          <v-textarea
            outlined
            :label="$t('model.prop.message')"
            v-model="message"
          ></v-textarea>
        </div>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapState } from "vuex";
import VueHtml2Canvas from "vue-html2canvas";
import QRious from "qrious";
import Vue from "vue";
Vue.use(VueHtml2Canvas);
export default {
  components: {},
  computed: mapState({
    //
  }),
  props: ["message", "item", "lang", "school"],
  data: () => ({
    output: null,
    loding: false,
    translatedMessage: "",
    noticeMessage: [],
  }),
  watch: {
    message: function (val) {
      this.message = val;
      this.noticeMessage = val.split("\n");
      this.printThis();
    },
  },
  created() {
    //
  },
  mounted() {
    this.reprocess();
    new QRious({
        element: document.getElementById('pass'),
        size: 90,
        value: ` ${process.env.VUE_APP_URL_CLASSROOM}${this.lang}/festivals/${this.item.code}?s=${this.school.School.code}`,
      });
    this.noticeMessage = this.message.split("\n");
    this.loding = true;
  },
  methods: {
    reprocess() {
      setTimeout(() => {
        this.printThis();
        this.loding = false;
      }, 1000);
    },
    async printThis() {
      const el = this.$refs.printMe;

      const options = {
        type: "dataURL",
        useCORS: true,
      };
      this.output = await this.$html2canvas(el, options);
    },
    downloadImg() {
      var link = document.createElement("a");
      link.download = "event-notice.png";
      link.href = this.output;
      link.click();
    },
  },
};
</script>
<style scoped>
.game-title {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.notice {
  background-color: black;
  background: url("../../../../assets/Festival/notice/background.jpeg")
    no-repeat center center fixed !important;
  width: 763px;
  height: 1080px;
  border: solid thin black;
  overflow: hidden;
}
.notice-title {
  color: black;
  font-size: 20px;
  padding: 10px;
  text-align: center;
}
.notice-body {
  position: relative;
}
.notice-image {
  height: 0;
  width: 0;
  overflow: hidden;
  position: absolute;
}
.qr-code {
  width: 200px;
}
.qr-section {
  margin-top: 250px !important;
}
.school-logo {
  
  padding-left: 520px !important;
  padding-top: 20px !important;
  position: absolute !important;
}
.school-logo img {
  width: 200px;
  height: 200px;
  /*border: 1px solid black;
  border-radius: 100px;
  background-color:white !important;*/
}
.notice-message {
  padding-left: 100px !important;
  padding-right: 100px !important;
  padding-top: 260px !important;
  font-size: 20px;
  line-height: 30px;
  color: black;
}
</style>